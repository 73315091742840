<template>
  <div class="contentReservas" :class="inside ? 'inside' : ''">
    
    <div class="title">
      <div class="div">
        <b-icon-calendar-check /> Reservas
        <b-input type="date" v-model="filtros.data" />
      </div>
      <div v-if="showFiltros" style="min-width: 300px" class="div">
        <label style="font-size: 12px" class="d-flex justify-content-between"
          >Filtro
          <b-checkbox
            v-model="filtros.somenteAtivo"
            :unchecked-value="true"
            :value="false"
            size="sm"
            switch
            >Exibir Cancelados</b-checkbox
          ></label
        >

        <b-input
          v-model="filtros.filtro"
          placeholder="nome, telefone, mesa, etc"
        />
      </div>
      <div class="div">
        <b-btn variant="success" @click="openForm(null)" v-if="!inside"
          ><b-icon-calendar-date /> Nova Reserva</b-btn
        >
        <div class="mt-1" v-if="!$store.state.isMobile && !inside">
          <b-radio-group v-model="visu" buttons button-variant="info" size="sm">
            <b-radio button value="table"> <b-icon-table /> Tabela</b-radio>
            <b-radio button value="card"><b-icon-card-image /> Card</b-radio>
          </b-radio-group>
        </div>
      </div>
    </div>
    <hr />
    <div v-if="loading" class="text-center"><b-spinner /><br /></div>
    <div
      v-if="!loading && filtrado && filtrado.length <= 0"
      class="text-center pt-4"
    >
      Nenhuma reserva encontrada!
    </div>
    <div v-if="!loading && filtrado && filtrado.length > 0">
      <div v-if="visu == 'table'" class="text-left">
        <b-table
          :items="filtrado"
          small
          style="font-size: 11px"
          hover
          stacked="md"
          :fields="[
            {
              key: 'id',
              label: 'Codidgo',
              sortable: true,
              class: 'text-center',
            },
            {
              key: 'nome',
              label: 'Cliente',
              sortable: true,
              class: 'font-weight-bold',
            },
            {
              key: 'status',
              label: 'Status',
              sortable: true,
              class: 'text-center',
            },
            { key: 'mesa', label: 'Mesa', sortable: true },
            { key: 'hora', label: 'Hora', sortable: true },
            { key: 'telefone', label: 'Telefone', sortable: true },
            {
              key: 'qtd_pessoas',
              label: 'Qtd. Pessoas',
              sortable: true,
              class: 'text-center',
            },
            { key: 'obs', label: 'Observações', sortable: true },
            { key: 'opc', label: '', sortable: true, class: 'text-center' },
          ]"
        >
          <template #cell(status)="row">
            <div
              class="p-0 text-center pl-2 pr-2"
              :class="
                row.item.dt_cancelado
                  ? 'bg-danger'
                  : row.item.dt_entrada
                  ? 'bg-success'
                  : row.item.dt_confirmado
                  ? 'bg-info'
                  : 'bg-warning'
              "
            >
              {{
                row.item.dt_cancelado
                  ? "Cancelado"
                  : row.item.dt_entrada
                  ? "Compareceu"
                  : row.item.dt_confirmado
                  ? "Confirmado"
                  : "Pendente"
              }}
            </div>
          </template>
          <template #cell(qtd_pessoas)="row">
            <i class="fa fa-user mr-1" /> {{ row.item.qtd_pessoas }}
            <i class="fa fa-child mr-1 ml-2 ml-sm-3" />
            {{ row.item.qtd_criancas }}
          </template>
          <template #cell(opc)="row">
            <b-btn
              size="sm"
              @click="compareceu(row.item)"
              :variant="'success'"
              v-if="!row.item.dt_entrada"
              class="mr-1"
            >
              Compareceu
            </b-btn>
            <b-btn
              size="sm"
              @click="confirmarReserva(row.item)"
              :variant="!row.item.dt_confirmado ? 'info' : 'warning'"
              v-if="!row.item.dt_entrada"
            >
              {{ !row.item.dt_confirmado ? "Confirmar" : "Desconfirmar" }}
            </b-btn>
            <b-btn
              size="sm"
              class="ml-1"
              @click="openForm(row.item)"
              variant="warning"
              :disabled="row.item.dt_entrada ? true : false"
              >Editar</b-btn
            >
            <b-btn
              size="sm"
              class="ml-1"
              variant="danger"
              :disabled="row.item.dt_entrada ? true : false"
              @click="cancelarReserva(row.item)"
              >Cancelar</b-btn
            >
          </template>
        </b-table>
      </div>
      <div class="rervasLista" v-if="visu == 'card'">
        <div
          v-for="re in filtrado"
          class="reserva"
          :key="`reserva_${re.id}_${Math.random()}`"
          @click="openOptions(re)"
        >
          <div class="Detail">
            <b-icon-calendar />
          </div>
          <h2 style="flex: 1">
            <b-icon-person /> {{ re.nome }}
            <br />
            <small>
              <small>
                {{ re.hora }}
                <span v-if="re.obs">
                  <br />
                  <small>
                    {{ re.obs }}
                  </small>
                </span>
              </small>
            </small>
          </h2>

          <div style="" class="p-2 text-right">
            <b>{{ re.mesa }}</b>
            <div v-if="!re.dt_entrada" class="text-warning">
              <b-icon-clock /> <small> Pendente </small>
            </div>
            <div v-if="re.dt_entrada" class="text-success">
              <b-icon-check-circle /> {{ re.dt_entrada | moment("HH:mm") }}
            </div>
            <div v-if="re.dt_cancelado" class="text-danger">
              <b-icon-x-circle /> {{ re.dt_cancelado | moment("HH:mm") }}
            </div>
            <div>
              <small>
                <small> 
                  <i class="fa fa-user mr-1" /> {{ re.qtd_pessoas }}
                  <i class="fa fa-child ml-2 mr-1" /> {{ re.qtd_criancas }}
                </small>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-reserva-form"
      size="lg"
      hide-footer
      title="Adicionar / Editar Reserva"
      :modal-class="$store.state.isMobile ? `modal-full p-0 ` : ''"
      :body-class="`p-0`"
      :content-class="`p-0 ${$store.state.isMobile ? `` : ''}`"
    >
      <mesa-new-reserva-form
        :obj="objEdit"
        :mesa="null"
        :mesas="mesas"
        :empresa="empresa"
        @close="closeForm"
        @closeRese="closeRese"
        :dataInicial="filtros.data"
      />
    </b-modal>
    <b-modal
      id="modal-options"
      hide-title
      hide-footer
      hide-header
      v-if="objOptions"
      @hidden="closeOptions()"
    >
      <h4>
        <b
          >Reserva {{ objOptions.nome }} - <b-icon-clock />
          {{ objOptions.hora }}</b
        >
      </h4>
      <hr />
      <h5 class="d-flex justify-content-between">
        <span>
          <i class="fa fa-user mr-1" /> {{ objOptions.qtd_pessoas }}
          <i class="fa fa-child mr-1 ml-4" /> {{ objOptions.qtd_criancas }}
        </span>
        <small v-if="objOptions.obs" class="ml-2">
          <b-icon-chat-dots /> {{ objOptions.obs }}
        </small>
      </h5>
      <h5 class="text-left mt-4">
        {{ objOptions.mesa }}
        <span
          v-if="objOptions.juncao && objOptions.juncao.length > 0"
          style="font-size: 9px"
        >
          <span
            v-for="m of objOptions.juncao"
            :key="`jun${m.mesa}_${Math.random()}`"
          >
            <b-icon-plus /> {{ m.mesa }}
          </span>
        </span>
      </h5>
      <hr />
      <b-btn
        class="text-left pl-4"
        size="lg"
        block
        variant="success"
        @click="compareceu(objOptions)"
        
        ><i class="fa fa-check-circle mr-1" /> {{ objOptions.dt_entrada?'Ir para Mesa':'Compareceu' }}</b-btn
      >
      <b-btn
        class="text-left pl-4"
        size="lg"
        block
        variant="warning"
        @click="openForm(objOptions)"
        ><i class="fa fa-edit mr-1" />Editar</b-btn
      >
      <b-btn
        size="lg"
        block
        variant="danger"
        @click="cancelarReserva(objOptions)"
        class="text-left pl-4"
        ><i class="fa fa-trash mr-1" /> Cancelar</b-btn
      >
      <b-btn class="text-left pl-4" size="lg" block @click="closeOptions()"
        ><i class="fa fa-door-open mr-1" /> Fechar</b-btn
      >
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { getReservas, storeReservas } from "./../../libs/MesasReservaLib";
import MesaNewReservaForm from "./MesaNewReservaForm.vue";
export default {
  components: { MesaNewReservaForm },
  props: {
    data: String,
    codMesa: [String, Number],
    mesas: Array,
    empresa: Object,
    showFiltros: {
      type: Boolean,
      default: true,
    },
    inside: {
      type: Boolean,
      default: false,
    },
    initialReserva: [String, Number],
  },
  mounted() {
    if (this.data) {
      this.filtros.data = this.data;
    }
    if (this.codMesa) {
      this.filtros.cod_mesa = this.codMesa;
    }
    if (this.initialReserva) {
      this.filtros.id = this.initialReserva;
    }
    if (location.href.includes("#reserva=")) {
      this.filtros.id = location.href.split("#reserva=")[1];
    }
    setTimeout(() => {
      this.carregar();
    }, 200);
  },
  data() {
    return {
      filtros: {
        cod_mesa: "",
        data: moment().format("YYYY-MM-DD"),
        filtro: "",
        somenteAtivo: true,
        id: null,
        cod_empresa: this.empresa.cod_empresa
      },
      lista: [],
      loading: false,
      objEdit: null,
      objOptions: null,
      visu: this.$store.state.isMobile || this.inside ? "card" : "table",
    };
  },
  watch: {
    $props: function () {
      if (this.data) {
        this.filtros.data = this.data;
      }
      if (this.codMesa) {
        this.filtros.cod_mesa = this.codMesa;
      }
      if (this.initialReserva) {
        this.filtros.id = this.initialReserva;
      }
      if (this.empresa) {
        this.filtros.cod_empresa = this.empresa.cod_empresa;
      }
      setTimeout(() => {
        this.carregar();
      }, 200);
    },
    "empresa": {
      deep: true,
      handler() {
        this.filtros.cod_empresa = this.empresa.cod_empresa;
        this.carregar();
      }
    },
    "filtros.data": function () {
      this.carregar();
    },
  },
  computed: {
    filtrado: function () {
      try {
        return this.lista.filter(
          (x) =>
            ((this.filtros &&
              this.filtros.filtro &&
              this.filtros.filtro != "" &&
              x.nome &&
              x.nome
                .toLowerCase()
                .trim()
                .indexOf(this.filtros.filtro.toLowerCase().trim()) >= 0) ||
              (x.telefone &&
                x.telefone
                  .toLowerCase()
                  .trim()
                  .indexOf(this.filtros.filtro.toLowerCase().trim()) >= 0) ||
              !this.filtros ||
              !this.filtros.filtro ||
              this.filtros.filtro.trim() == "") &&
            ((this.filtros &&
              this.filtros.somenteAtivo === false &&
              (!x.dt_cancelado || true)) ||
              (this.filtros.somenteAtivo && x.dt_cancelado === null))
        );
      } catch (e) {
        console.log("ERROR ON FILTRO", e, this.filtros);
        return this.lista;
      }
    },
  },
  methods: {
    closeOptions() {
      this.objOptions = null;
      this.$bvModal.hide("modal-options");
    },
    async openOptions(reserva) {
      this.objOptions = reserva;
      await this.$nextTick();
      this.$forceUpdate();
      setTimeout(() => {
        this.$bvModal.show("modal-options");
      }, 200);
    },
    compareceu(reserva) {
      this.closeOptions();
      if (!this.inside) {
        if (!reserva.cod_mesa) {
          this.$swal(
            "Reserva sem mesa selecionada!",
            "Esta reserva não possui uma mesa ainda, ao escolher a mesa você irá vincular a reserva!",
            "warning"
          );
        }
        this.$router.push(
          "/mesas/geral/#openMesa=" +
            reserva.cod_mesa +
            "#reserva=" +
            reserva.id
        );
      }
      this.$emit("compareceu", reserva);
    },
    openForm(reserva) {
      this.closeOptions();
      this.objEdit = reserva;
      this.$bvModal.show("modal-reserva-form");
    },
    closeForm(changeDateTo) {
      this.closeOptions();

      console.log("PASSOU AQUI");
      if (changeDateTo) {
        // this.filtros.data = changeDateTo;
      }
      this.carregar();
      this.$bvModal.hide("modal-reserva-form");
    },
    closeRese() {
      this.$bvModal.hide("modal-reserva-form");
    },
    async confirmarReserva(reserva) {
      this.closeOptions();

      this.$swal({
        title: reserva.dt_confirmado
          ? "Retirar confirmação"
          : "Confirmar Reserva?",
        text:
          "Tem certeza que deseja " +
          (reserva.dt_confirmado
            ? "remover a confirmação da"
            : "confirmar esta") +
          " reserva?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: reserva.dt_confirmado
          ? "Retirar confirmação!"
          : "Confirmar!",
        confirmButtonType: "danger",
      }).then(async (r) => {
        if (r.isConfirmed) {
          let res = await storeReservas({
            ...reserva,
            cod_usuario_confirmacao: reserva.dt_confirmado
              ? null
              : this.user.cod_usuario,
            dt_confirmado: reserva.dt_confirmado
              ? null
              : moment().format("YYYY-MM-DD HH:mm:ss"),
            confirmado: 1,
          });
          console.log("RES", res);
          if (res && res.success) {
            this.$swal({
              title: "Salvo com sucesso!",
              toast: true,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
          }
        }
        this.carregar();
      });
    },
    async cancelarReserva(reserva) {
      this.closeOptions();

      this.$swal({
        title: reserva.dt_cancelado ? "Reativar Reserva?" : "Cancelar Reserva?",
        text:
          "Tem certeza que deseja " +
          (reserva.dt_confirmado ? "reativar a " : "cancelar esta") +
          " reserva?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Não",
        confirmButtonText: reserva.dt_cancelado ? "Reativar!" : "Cancelar!",
        confirmButtonType: "danger",
      }).then(async (r) => {
        if (r.isConfirmed) {
          let res = await storeReservas({
            ...reserva,
            cod_usuario_cancelado: reserva.dt_cancelado
              ? null
              : this.user.cod_usuario,
            dt_cancelado: reserva.dt_cancelado
              ? null
              : moment().format("YYYY-MM-DD HH:mm:ss"),
          });
          console.log("RES", res);
          if (res && res.success) {
            this.$swal({
              title: "Salvo com sucesso!",
              toast: true,
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
          }
        }
        this.carregar();
      });
    },
    async carregar() {
      this.loading = true;
      try {
        this.lista = [];
        console.log("buscando reservas", this.filtros);
        let res = await getReservas(this.filtros);
        console.log("result", res);
        if (res && res.success) {
          this.lista = res.data.filter(
            (x) => (!this.inside && !x.reserva_id) || this.inside
          );
          if (location.href.includes("#reserva=")) {
            console.log("1");
            let reserva = this.lista.find(
              (x) => x.id == location.href.split("#reserva=")[1]
            );
            if (reserva) {
              console.log("2");
              this.compareceu(reserva);
              this.$router.replace("/mesas/geral");
            }
          }
        }
      } catch (error) {
        console.log("ERROR ", error);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.inside {
  position: relative !important;
  padding-top: 0px !important;
}
.contentReservas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  overflow-y: auto;
  padding: 10px;
  @media only screen and (min-width: 575px) {
    padding-top: 100px;
  }
  .title {
    font-size: 20px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    .div {
      @media only screen and (max-width: 575px) {
        justify-content: center;
        margin-bottom: 15px;
        width: 100%;
        .btn {
          width: 100%;
        }
      }
    }
  }

  .rervasLista {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    .reserva {
      .Detail {
        width: 45px;
        height: 90px;
        background-color: #292929;
        color: white;
        padding: 5px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 0 12rem 12rem 0;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 20px;
      }
      display: flex;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: white;
      border-left-width: 5px;
      // border-radius: 15px;
      border: 2px solid #292929;
      width: 100%;
      cursor: pointer;
      // min-height: 180px;

      margin: 3px;
      text-align: left;
      font-size: 14px;
      min-width: 300px;
      max-width: 100%;
      color: #292929;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        background-color: #292929;
        color: white;
        .Detail {
          background-color: #111;
        }
      }
      // cursor: pointer;

      @media only screen and (max-width: 575px) {
        width: 100% !important;
        max-width: 100%;
      }

      @media only screen and (min-width: 1366px) {
        max-width: 30%;
      }

      @media only screen and (min-width: 1678px) {
        max-width: 22%;
      }

      &:hover {
        // box-shadow: 0px 0px 10px rgba(111, 111, 111, 0.1);
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .OPCS {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        flex-wrap: wrap;
        // transition: all 0.3s ease;
        font-size: 14px;

        &:hover {
          // font-size: 7px;
        }

        div {
          flex: 1;
          text-align: center;
          cursor: pointer;

          @media only screen and (max-width: 575px) {
            width: 40%;
            flex: auto;
          }

          transition: all 0.3s ease;
          border-radius: 15px;
          padding: 10px;
          &:hover {
            flex: 2;
            background-color: #ccc;
            color: black;
            // font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
.det {
  div {
    flex: 1;
    background-color: #fff;
    margin: 2px;
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
  }
}
</style>
