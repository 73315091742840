<template>
  <div>
    <b-card title="Informações da Reserva" body-class="p-1 p-sm-4">
      <div class="row">
        <div class="col-6">
          <b-form-group label="Data">
            <b-input type="date" v-model="reserva.data" />
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group label="Hora">
            <b-input type="time" v-model="reserva.hora" />
          </b-form-group>
        </div>
        <div class="col-12" v-if="!mesa && !inside">
          <hr />
          <mesa-new-vincular-mesas
            v-if="!mesa"
            @change="(m) => (mesas = m)"
            :somenteDisponiveis="false"
            :title="'Selecionar Mesa(s)'"
            :mesas="mesasToSend"
            :empresa="empresa"
            :initialSelect="initialSelect"
            :data="reserva.data"
            :showReservas="true"
          />
          <hr />
        </div>
        <div class="col-12 col-sm-8">
          <b-form-group label="Nome">
            <b-input v-model="reserva.nome" />
          </b-form-group>
        </div>
        <div class="col-12 col-sm-4">
          <b-form-group label="Telefone" pattern="\d*">
            <b-input
              pattern="\d*"
              v-mask="['(##) ####-####', '(##) #####-####']"
              v-model="reserva.telefone"
            />
          </b-form-group>
        </div>
        <div class="col-12 col-sm-3">
          <b-form-group label="Qtd. Pessoas">
            <b-spinbutton v-model="reserva.qtd_pessoas" min="0" />
          </b-form-group>
        </div>
        <div class="col-12 col-sm-3">
          <b-form-group label="Qtd. Crianças">
            <b-spinbutton v-model="reserva.qtd_criancas" min="0" />
          </b-form-group>
        </div>
        <div class="col-12 col-sm-6">
          <b-form-group label="Observações">
            <b-input v-model="reserva.obs" />
          </b-form-group>
        </div>
        <div class="col-12 mt-2 text-right" v-if="!inside">
          <hr />
          <b-btn variant="success" class="mr-1" @click="salvar"
            ><i class="fa fa-save mr-1" /> Salvar</b-btn
          >
          <b-btn variant="secondary" @click="$emit('close')"
            ><i class="fa fa-times mr-1" /> Cancelar</b-btn
          >
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";
import { storeReservas } from "../../libs/MesasReservaLib";
import MesaNewVincularMesas from "./MesaNewVincularMesas.vue";

export default {
  components: { MesaNewVincularMesas },
  props: {
    inside: {
      type: Boolean,
      default: false,
    },
    mesa: Object,
    obj: Object,
    mesas: Array,
    empresa: Object,
    dataInicial: String,
  },
  mounted() {
    if (this.mesa) {
      this.mesaObj = this.mesa;
      // console.log("MESAAAAAAAAA", this.mesaObj);
    }
    this.mesasToSend = JSON.parse(JSON.stringify(this.mesas));
    if (this.obj) {
      this.reserva = this.obj;
      this.initialSelect.push(this.reserva.cod_mesa);
      if (this.reserva.juncao && this.reserva.juncao.length > 0) {
        this.initialSelect.push(...this.reserva.juncao.map((x) => x.cod_mesa));
      }
    } else {
      this.initialSelect = [];
    }

    if (this.dataInicial) {
      console.log("datainicial: " + this.dataInicial);
      this.reserva.data = this.dataInicial;
    }
  },
  data() {
    return {
      mesasToSend: [],
      mesaObj: null,
      reserva: {
        data: moment().format("YYYY-MM-DD"),
        hora: "20:00",
        nome: "",
        telefone: "",
        qtd_pessoas: 1,
        qtd_criancas: 0,
        obs: "",
        cod_empresa: this.empresa.cod_empresa,
      },
      initialSelect: [],
    };
  },
  watch: {},
  computed: {},
  methods: {
    async salvar() {
      let erros = [];
      // if (!this.reserva.nome || this.reserva.nome == "") {
      //   erros.push("Informe um nome para a reserva!");
      // }
      // if (!this.reserva.telefone || this.reserva.telefone == "") {
      //   erros.push("Informe um telefone para a reserva!");
      // }
      if (!this.reserva.data || this.reserva.data == "") {
        erros.push("Informe uma data para a reserva!");
      }
      if (!this.reserva.hora || this.reserva.hora == "") {
        erros.push("Informe uma hora para a reserva!");
      }
      // if (
      //   !this.mesaObj &&
      //   !this.reserva.cod_mesa &&
      //   !this.mesas.find((x) => x.selected === true)
      // ) {
      //   erros.push("Selecione ao menos uma mesa para realizar a reserva");
      // }
      if (
        !this.reserva.qtd_pessoas ||
        this.reserva.qtd_pessoas < 1 ||
        this.reserva.qtd_pessoas == ""
      ) {
        erros.push("Informe a quantidade de pessoas para a reserva!");
      }

      if (erros.length > 0) {
        this.$swal({
          title: "Atenção",
          html: `Alguns campos precisam de atenção <hr/> ${erros.join(
            "<hr/>"
          )}`,
          icon: "error",
        });
        return false;
      }
      // this.$socket.emit("mesas:reserva", {
      //   mesa: this.mesaObj,
      //   reserva: ,
      // });
      console.log(
        "mesa",
        this.mesas.filter((x) => x.selected === true)
      );
      let obj = {
        ...this.reserva,
        cod_mesa:
          this.mesas.filter((x) => x.selected === true).length > 0
            ? this.mesas.filter((x) => x.selected === true)[0].cod_mesa
            : !this.reserva.cod_mesa
            ? this.mesaObj
              ? this.mesaObj.cod_mesa
              : this.mesas.find((x) => x.selected === true)?.cod_mesa
            : this.mesaObj &&
              this.mesaObj.juncao &&
              this.mesaObj.juncao.length > 0
            ? this.mesaObj.juncao[0].cod_mesa
            : this.reserva.cod_mesa,
        juncao: this.mesaObj
          ? this.mesaObj.juncao.map((it) => ({
              cod_mesa: it.cod_mesa,
            }))
          : this.mesas
              .filter((x) => x.selected)
              .map((it) => ({
                cod_mesa: it.cod_mesa,
              })),
        cod_usuario_reserva: this.user.cod_usuario,
      };
      console.log("send obj!!!!!!", obj);
      let res = await storeReservas(obj);
      console.log("RES", res);

      if (res && res.success) {
        this.$swal({
          title: "Reserva enviada com suceso!",
          icon: "success",
          toast: true,
          timer: 3000,
          // timer: 2000,
          position: "top",
          showConfirmButton: false,
        });
        this.$emit("close", this.reserva.data);
        return true;
      } else {
        this.$swal("Erro", "Erro ao processar reserva!", "error");
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
