<template>
  <div>
    <div class="VincularMesas">
      <b><b-icon-arrows-move /> {{ title }}</b>
      <div class="ListaMesas">
        <div
          v-for="mesa in mesasSelecionadas"
          :key="`mesaJoin${mesa.cod_mesa}`"
          @click="selectMesa(mesa)"
        >
          {{ mesa.mesa.replace("Mesa", "") }}
          <div class="btExcMesa">
            <b-icon-x-circle />
          </div>
        </div>
        <div @click="vincularMesa()">
          <b-icon-plus-circle />
        </div>
      </div>
    </div>
    <b-modal
      id="modal-show-mesas-openeds"
      hide-footer
      :title="`Juntar Com ${mesaObj && mesaObj.mesa ? mesaObj.mesa : 'Mesa'}`"
      size="lg"
    >
      <mesa-salao-mapa
        v-if="layout"
        @select="sel"
        :layout="layout.layout"
        :selectable="true"
        :mesaList="mesaList"
        :somenteDisponiveis="somenteDisponiveis"
        :showReservas="showReservas"
        :data="data"
      />
      <!-- <div class="mesaDispLista">
        <div
          v-for="mesa in mesasDisponiveis"
          :key="`mesaDisp${mesa.cod_mesa}`"
          :class="{ selected: mesa.selected === true }"
          @click="selectMesa(mesa)"
        >
          <b-icon-check-circle
            v-if="mesa.selected"
            class="mr-2"
            style="font-size: 40px"
          />
          {{ mesa.mesa }}
        </div>
      </div> -->
    </b-modal>
  </div>
</template>

<script>
import MesaSalaoMapa from "./MesaSalaoMapa.vue";
export default {
  name: `VincularMesas${Math.round()}`,
  components: { MesaSalaoMapa },
  props: {
    empresa: Object,
    mesas: Array,
    mesaObj: Object,
    initialSelect: Array,
    title: {
      type: String,
      default: "Juntar Mesas",
    },
    somenteDisponiveis: {
      type: Boolean,
      default: true,
    },
    showReservas: {
      type: Boolean,
      default: true,
    },
    data: String,
  },
  mounted() {
    this.fillMesas();
    // console.log("mesas L", this.mesaList);
    this.verificaLayout();
    if (this.initialSelect) {
      setTimeout(() => { 
        this.fillMesas();
      },500)
    }
  },
  data() {
    return {
      mesaList: [],
      layout: null,
    };
  },
  watch: {
    initialSelect: function () {
      // this.fillMesas();
    },
    mesaList: {
      deep: true,
      handler() {
        // console.log("emit", this.mesaList);
        this.$emit("change", this.mesaList);
      },
    },
    // mesas: {
    //   deep: true,
    //   handler() {
    //     this.fillMesas();
    //     this.verificaLayout();
    //   },
    // },
  },
  computed: {
    mesasSelecionadas: function () {
      return this.mesaList.filter((x) => x.selected === true);
    },
    mesasDisponiveis: function () {
      return this.mesaList.filter(
        (x) =>
          !this.somenteDisponiveis ||
          (this.somenteDisponiveis &&
            (!x.id || x.id == 0) &&
            !x.selected &&
            (!this.mesaObj ||
              (this.mesaObj && x.cod_mesa != this.mesaObj.cod_mesa)))
      );
    },
  },
  methods: {
    sel(mesa) {
      console.log("sel", mesa);
      this.selectMesa(
        this.mesaList.find((x) => x.cod_mesa == mesa.cod_mesa)
        ||
        this.mesas.find((x) => x.cod_mesa == mesa.cod_mesa)
      );
    },
    getMesa(cod_mesa) {
      return this.mesas.find((x) => x.cod_mesa == cod_mesa);
    },
    verificaLayout() {
      if (
        this.empresa &&
        this.empresa.configs &&
        this.empresa.configs.mesasLayout &&
        this.empresa.configs.mesasLayout.length > 0
      ) {
        let has = this.empresa.configs.mesasLayout.find(
          (x) => x.ativo === true
        );
        if (has) {
          this.layout = has;
          this.fillMesaObjs();
        }
      }
    },
    fillMesaObjs() {
      if (this.layout && this.layout.layout) {
        for (let r of this.layout.layout) {
          for (let it of r.items) {
            it.obj = this.getMesa(it.cod_mesa);
          }
        }
        // console.log("layout", this.layout.layout);
      }
    },
    fillMesas() {
      this.mesaList = JSON.parse(
        JSON.stringify(
          this.mesas.filter(
            (x) =>
              !this.somenteDisponiveis ||
              (this.somenteDisponiveis && (!x.id || x.id == 0))
          )
        )
      ).map((m) => ({
        ...m,
        selected: (!this.initialSelect || this.initialSelect.length <= 0
          ? false
          : this.initialSelect.includes(m.cod_mesa)) || false,
      }));

      console.log("aqui", this.mesaList);
    },
    selectMesa(mesa) {
      // console.log("mesaSEL",mesa)
      if (mesa) {
        if (mesa.selected === null) {
          // this.selected = false;
        }
        console.log("selected", mesa.selected);
        mesa.selected = !mesa.selected;
        // this.$bvModal.hide("modal-show-mesas-openeds");
      }
    },
    vincularMesa() {
      this.$bvModal.show("modal-show-mesas-openeds");
    },
  },
};
</script>

<style lang="scss" scoped>
.mesaDispLista {
  padding: 10px;
  display: flex;
  flex-direction: column;
  div {
    border: 2px solid #eee;
    border-radius: 5px;
    margin: 2px;
    padding: 5px;
    height: 50px;
    background-color: #fefefe;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #888;
  }
  .selected {
    border: green;
    background-color: lightgreen;
    color: green;
    border-width: 5px;
  }
}
.VincularMesas {
  padding: 10px;
  .ListaMesas {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: auto;
    flex-wrap: wrap;
    transition: all 0.4s ease;
    div {
      width: 80px;
      height: 80px;
      margin: 3px;
      background-color: #f1f1f1;
      color: #888;
      border-radius: 5px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 35px;
      position: relative;
      transition: all 0.4s ease;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
      }
      .btExcMesa {
        color: red;
        position: absolute;
        font-size: 20px;
        right: -6px;
        top: -6px;
        width: 20px;
        height: 20px;
        z-index: 1;
      }
    }
  }
}
</style>
